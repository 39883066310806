<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-row>
      <b-col cols="12">
        <b-card class="">
          <h4>اساس قیمت گذاری</h4>

          <b-row>
            <b-col
                cols="12"
                md="6"
                class="my-2"
            >
              <b-form-group
                  label="قیمت گذاری برای"
                  label-for="type"
              >
                <v-select
                    v-model="type"
                    :options="typeOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="type"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <!--  setPriceBySeminar       -->
        <b-card v-if="type === 1">

          <h4 class="mb-2">قیمت گذاری برای کل صندلی های سالن</h4>

          <b-row>

            <!-- Field:  price -->
            <b-col
                cols="12"
                md="4"
            >
              <b-form-group
                  label="قیمت (تومان)"
                  label-for="price"
              >
                <b-form-input
                    id="price"
                    v-model="price"
                />
              </b-form-group>
              <b-button
                  variant="primary"
                  class=" mr-sm-1 col-12 col-md-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="setPriceBySeminar"
              >
                ثبت
              </b-button>
            </b-col>

          </b-row>
        </b-card>

        <!--  setPriceBySection       -->
        <b-card v-if="type === 2">

          <h4 class="mb-2">قیمت گذاری برای کل صندلی های یک جایگاه</h4>

          <b-row>

              <b-col
                  v-if="sections !== null"
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="انتخاب جایگاه"
                    label-for="section"
                >
                  <v-select
                      v-model="section"
                      :options="sections"
                      label="name"
                      :reduce="name => name.sectionInfo.sectionId"
                      :clearable="false"
                      input-id="section"
                  />
                </b-form-group>
              </b-col>

            <!--   Field:  price -->
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group
                  label="قیمت (تومان)"
                  label-for="price"
              >
                <b-form-input
                    id="price"
                    v-model="price"
                />
              </b-form-group>
            </b-col>

            <b-button
                variant="primary"
                class=" mr-sm-1 col-12 col-md-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="setPriceBySection"
            >
              ثبت
            </b-button>

          </b-row>
        </b-card>

        <!--  setPriceBySection       -->
        <b-card v-if="type === 3">

          <h4 class="mb-2">قیمت گذاری برای کل صندلی های یک ردیف جایگاه</h4>

          <b-row>

            <b-col
                v-if="sections !== null"
                cols="12"
                md="6"
            >
              <b-form-group
                  label="انتخاب جایگاه"
                  label-for="section"
              >
                <v-select
                    v-model="section"
                    :options="sections"
                    label="name"
                    :reduce="name => name.sectionInfo.sectionId"
                    :clearable="false"
                    input-id="section"
                />
              </b-form-group>
            </b-col>

            <b-col
                v-if="rows !== null"
                cols="12"
                md="6"
            >
              <b-form-group
                  label="انتخاب ردیف"
                  label-for="section"
              >
                <v-select
                    v-model="row"
                    :options="rows"
                    label="rowNumber"
                    :reduce="name => name.rowId"
                    :clearable="false"
                    input-id="section"
                />
              </b-form-group>
            </b-col>

            <!--   Field:  price -->
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group
                  label="قیمت (تومان)"
                  label-for="price"
              >
                <b-form-input
                    id="price"
                    v-model="price"
                />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row class="ml-1">
            <b-button
                variant="primary"
                class="col-12 col-md-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="setPriceByRow"
            >
              ثبت
            </b-button>
          </b-row>
        </b-card>

      </b-col>
    </b-row>

  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay,
} from 'bootstrap-vue'
import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ReserveChangePriceByRowRequest} from "@/libs/Api/Reserve";
import {ReserveChangePriceBySectionRequest} from "@/libs/Api/Reserve";
import {ReserveChangePriceBySeminarRequest} from "@/libs/Api/Reserve";
import {SectionGetBySaloonIdAndSeminarIdRequest, SectionGetBySaloonIdRequest} from "@/libs/Api/Section";
import {RowGetAllBySectionIdRequest} from "@/libs/Api/Row";

export default {
  title: "قیمت گذاری سمینار - پنل ادمین مکس ",
  name: "SeminarSetPrice",
  data() {
    return {
      overlay: false,
      seminarId: this.$route.params.id,
      sections:null,
      section: 1,
      rows:null,
      row:null,
      type: 1,
      typeOptions: [
        {label: 'کل سالن', value: 1},
        {label: 'جایگاه', value: 2},
        {label: 'ردیف', value: 3},
      ],
      price: '',
    }
  },
  async created(){
    await this.getSections();
    await this.getRows(this.section);
  },
  methods: {
    async setPriceBySeminar() {
      let _this = this;
      _this.overlay = true

      let data = {
        seminarId: _this.seminarId,
        price: _this.price
      };

      let reserveChangePriceBySeminarRequest = new ReserveChangePriceBySeminarRequest(_this);
      reserveChangePriceBySeminarRequest.setParams(data);
      await reserveChangePriceBySeminarRequest.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.$router.push('/apps/seminar/list');
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async setPriceBySection() {
      let _this = this;
      _this.overlay = true

      let data = {
        seminarId: _this.seminarId,
        price: _this.price,
        sectionId:_this.section
      };

      let reserveChangePriceBySectionRequest = new ReserveChangePriceBySectionRequest(_this);
      reserveChangePriceBySectionRequest.setParams(data);
      await reserveChangePriceBySectionRequest.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        // _this.$router.push('/apps/seminar/list');
        window.location.reload();
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async setPriceByRow(){
      let _this = this;
      _this.overlay = true

      let data = {
        seminarId: _this.seminarId,
        price: _this.price,
        rowId:_this.row
      };

      let reserveChangePriceByRowRequest = new ReserveChangePriceByRowRequest(_this);
      reserveChangePriceByRowRequest.setParams(data);
      await reserveChangePriceByRowRequest.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        // _this.$router.push('/apps/seminar/list');
        window.location.reload();
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async getSections(){
      let _this = this;

      let data = {
        saloonId:1,
        seminarId:_this.seminarId,
      };

      let sectionGetBySaloonIdRequest = new SectionGetBySaloonIdAndSeminarIdRequest(_this);
      sectionGetBySaloonIdRequest.setData(data);
      await sectionGetBySaloonIdRequest.fetch(function (content){
        content.forEach(el => {
          if(el.sectionInfo.level === 1 ){
            el.name = `${'همکف '+el.sectionInfo.name}`;
            el.id = `${'همکف '+el.sectionInfo.sectionId}`;
          }else {
            el.name = `${'بالکن '+el.sectionInfo.name}`;
            el.id = `${'همکف '+el.sectionInfo.sectionId}`;
          }
        })
        _this.sections = content;
      },function (error){
        console.log(error)
      })
    },
    async getRows(param){
      let _this = this;

      let rowGetAllBySectionIdRequest = new RowGetAllBySectionIdRequest(_this);
      rowGetAllBySectionIdRequest.setId(param);
      await rowGetAllBySectionIdRequest.fetch(function (content){
        _this.rows = content;
      },function (error){
        console.log(error)
      })
    },
  },
  watch:{
    section:function (nv,ov) {
      this.getRows(nv);
    },
  },
  components: {
    NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
  },
}
</script>

<style scoped>

</style>